// SI EN USO

import React from "react";
import Video from "../components/Video";
import FunFacts from "../containers/FunFacts/FunFacts";
import MissionContainer from "../containers/MissionContainer";
import TextAbout from "./TextAbout";

const AboutUs = () => {
  return (
    <main className="wrapper">
      <MissionContainer />

      <div className="video-block">
        <Video />
      </div>

      <FunFacts className="funfacts pd-top-lg bg_2" />
      <TextAbout />
    </main>
  );
};

export default AboutUs;
