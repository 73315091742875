// SI EN USO

import Isotope from "isotope-layout";
import React, { useEffect, useState } from "react";
import ProjectCard from "../components/ProjectElements/ProjectCard";
import SectionGridLines from "../components/SectionGridLines";

const Portfolio = () => {
  const ProjectData01 = [
    {
      id: "1",
      img: "images/portfolio/galeria/1.png",
      details: "Decoración para Eventos",
      tag: "decoracion",
    },
    {
      id: "2",
      img: "images/portfolio/galeria/2.png",
      details: "Decoración para Eventos",
      tag: "decoracion",
    },
    {
      id: "3",
      img: "images/portfolio/galeria/3.png",
      details: "Catering y mesas de quesos",
      tag: "decoracion",
    },
    {
      id: "4",
      img: "images/portfolio/galeria/4.png",
      details: "Catering y Mesas de Quesos",
      tag: "decoracion",
    },
    {
      id: "5",
      img: "images/portfolio/galeria/5.png",
      details: "Decoración creativa para tus eventos",
      tag: "catering",
    },
    {
      id: "6",
      img: "images/portfolio/galeria/6.png",
      details: "Decoración creativa para tus eventos",
      tag: "catering",
    },
    {
      id: "7",
      img: "images/portfolio/galeria/7.png",
      details: "Decoración de eventos con globos",
      tag: "catering",
    },
    {
      id: "8",
      img: "images/portfolio/galeria/8.png",
      details: "Decoración de eventos con globos",
      tag: "catering",
    },
    {
      id: "9",
      img: "images/portfolio/galeria/9.png",
      details: "Decoración para Eventos",
      tag: "eventos",
    },
    {
      id: "10",
      img: "images/portfolio/galeria/10.png",
      details: "Decoración para Eventos",
      tag: "eventos",
    },
    {
      id: "11",
      img: "images/portfolio/galeria/11.png",
      details: "Catering y mesas de quesos",
      tag: "eventos",
    },
    {
      id: "12",
      img: "images/portfolio/galeria/12.png",
      details: "Catering y Mesas de Quesos",
      tag: "eventos",
    },
    {
      id: "13",
      img: "images/portfolio/galeria/13.png",
      details: "Decoración creativa para tus eventos",
      tag: "globos",
    },
    {
      id: "14",
      img: "images/portfolio/galeria/14.png",
      details: "Decoración creativa para tus eventos",
      tag: "globos",
    },
    {
      id: "15",
      img: "images/portfolio/galeria/15.png",
      details: "Decoración de eventos",
      tag: "globos",
    },
    {
      id: "16",
      img: "images/portfolio/galeria/16.png",
      details: "Decoración de eventos",
      tag: "globos",
    },
  ];

  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    Isotope.current = new Isotope(".grid", {
      itemSelector: ".grid-item",
      percentPosition: true,
      transformsEnabled: true,
      transitionDuration: "700ms",
      resize: true,
      fitWidth: true,
      columnWidth: ".grid-sizer",
    });

    // cleanup
    return () => Isotope.current.destroy();
  }, []);

  // handling filter key change
  useEffect(() => {
    filterKey === "*" ? Isotope.current.arrange({ filter: `*` }) : Isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <section className="projects packery">
      <SectionGridLines />
      <div className="container">
        <div className="portfolio-filters-content">
          <div className="filters-button-group">
            <button className="button" onClick={handleFilterKeyChange("*")}>
              Todo <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("decoracion")}>
              Decoración <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("catering")}>
              Catering <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("eventos")}>
              Eventos <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("globos")}>
              Globos <sup className="filter-count"></sup>
            </button>
          </div>
        </div>
        <div className="grid gutter-20 clearfix">
          <div className="grid-sizer"></div>
          {ProjectData01.map((data) => (
            <ProjectCard key={data.id} data={data} />
          ))}
        </div>
        {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
      </div>
    </section>
  );
};

export default Portfolio;
