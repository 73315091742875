// SI EN USO

import React from "react";

const MissionContainer = () => {
  return (
    <section className="mission">
      <div className="container">
        <div className="mission_top_part">
          <div className="section-header text-center">
            <h6 className="text-white text-uppercase">Nuestra Misión</h6>
            <p className="text-gray-600">Tenemos la clave para convertir tus celebraciones en experiencias únicas y llenas de magia.</p>
            <br></br>
            <img src="images/bg/about_bg.webp" alt="" />
          </div>
        </div>

        <div className="mission_bottom_part">
          <div className="row justify-content-center">
            <div className="col-lg-4 pe-lg-4">
              <div className="section-header">
                <h3 className="text-white text-uppercase border-line">CON AMORE EVENTS</h3>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="mission_content">
                <p>
                  ¡Celebra con Amore Events y deja que el amor y la pasión por los eventos se unan para crear momentos inolvidables juntos! En Con
                  Amore Events entendemos la importancia de conectar con otros a través de momentos y eventos significativos
                </p>
                <p>Con Amore Events te ofrece una exquisita mesa de queso de alta calidad que hará que tu evento sea todo un éxito.</p>
                <p>¡Descubre cómo podemos ayudarte a crear conexiones auténticas y duraderas a través de eventos inolvidables!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionContainer;
