import React, { useEffect } from "react";

const ThemeSwitcher = () => {
  useEffect(() => {
    var icon = document.getElementById("mode_switcher");

    if (localStorage.getItem("theme") === "null") {
      localStorage.setItem("theme", "dark");
    }
    icon.onclick = function () {
      document.body.classList.toggle("bg-white");
      if (document.body.classList.contains("bg-white")) {
        icon.innerHTML = '<span><i class="bi bi-moon-fill"></i></span>';
        localStorage.setItem("theme", "dark");
      } else {
        icon.innerHTML = '<span><i class="bi bi-sun-fill"></i></span>';
        localStorage.setItem("theme", "light");
      }
    };
  }, []);
  return <div id="mode_switcher"></div>;
};

export default ThemeSwitcher;
