// SI EN USO

import React from "react";
import Portfolio from "../../components/Portfolio";
import PageBanner03 from "../../containers/PageBanner03";

const Project01 = () => {
  return (
    <>
      <PageBanner03 title="Galeria" activePage="Galeria" />
      <Portfolio text="Más" className="btn gray" />
    </>
  );
};

export default Project01;
