import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
import Project01 from "../Pages/ProjectPages/galeria";
import Service01 from "../Pages/ServicePages/Service";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Home01 />,
      },
      {
        path: "/galeria",
        element: <Project01 />,
      },
      {
        path: "/service",
        element: <Service01 />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
