// SI EN USO

import $ from "jquery";
import React, { useEffect } from "react";
import FunFact from "../../components/FunFactElements/FunFact";

const FunFacts = (props) => {
  const FunFactData = [
    {
      id: "1",
      contentName: "Servicios",
      contentNumber: "4",
      img: "images/favicon.ico",
    },
    {
      id: "2",
      contentName: "Eventos Realizados",
      contentNumber: "100",
      plus: "+",
      img: "images/favicon.ico",
    },
    {
      id: "3",
      contentName: "Servicio Comprobado",
      contentNumber: "5",
      img: "images/favicon.ico",
    },
    {
      id: "4",
      contentName: "Años de Experiencia",
      contentNumber: "10",
      img: "images/favicon.ico",
    },
  ];

  useEffect(() => {
    var a = 0;
    $(window).on("scroll", function () {
      var oTop = $("#funfacts").offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $(".fun-number").each(function () {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 4000,
                easing: "swing",
                step: function (now) {
                  $(this).text(Math.ceil(now));
                },
              }
            );
        });
        a = 1;
      }
    });
  }, []);
  return (
    <section className={props.className} id="funfacts">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="funfacts_inner">
              {FunFactData.map((data) => (
                <FunFact key={data.id} data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFacts;
