// SI EN USO

import React from "react";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  return (
    <section className="services bg-dark-100 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ServiceCard
              number="01"
              imgDark="images/services_logo.png"
              imgLight="images/services_logo.png"
              title="Mesas de Quesos"
              text="Las mejores mesas de quesos y aperitivos con productos frescos y la mejor calidad cuidando cada detalle para sorprender y complacer a tus invitados. Para todo tipo de evento."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="02"
              imgDark="images/services_logo.png"
              imgLight="images/services_logo.png"
              title="Decoración y Catering"
              text="Trabajamos con amor para crear un ambiente único y especial para ti. Si estás pensando en organizar una fiesta inolvidable, no dudes en contactarnos. Trabajaremos contigo para crear una fiesta que se ajuste a tu estilo y presupuesto."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="03"
              imgDark="images/services_logo.png"
              imgLight="images/services_logo.png"
              title="Papelería creativa"
              text="Transformamos cualquier espacio en un ambiente mágico y lleno de vida. Nuestras decoraciones son perfectas para cualquier ocasión especial o una fecha que quieras recordar. ¡No lo pienses más y contacta con nosotros!"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
