import React from "react";
import Form from "./Form";
import Paragraph from "./Paragraph";
import SectionGridLines from "./SectionGridLines";

const ContactForm = () => {
  return (
    <section className="contact_us bg-dark-200">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="section-header">
              <h1 className="text-white text-uppercase mb-4">HABLEMOS DE TU PRÓXIMO EVENTO</h1>
              <Paragraph text="El talento de Con Amore Events es muy amplio y profundo. Los miembros de nuestro equipo son algunos de los mejores profesionales de la industria. Hemos agrupado nuestro trabajo en cinco categorías: lugares, espacios, experiencias y eventos." />
            </div>
          </div>

          <div className="col-lg-6">
            <Form />
          </div>
        </div>
      </div>

      <SectionGridLines />
    </section>
  );
};

export default ContactForm;
