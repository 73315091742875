// SI EN USO

import React from "react";
import Paragraph from "./Paragraph";
import SubHeading from "./SubHeading";

const FormText = () => {
  return (
    <div className="col-lg-5">
      <div className="section-header">
        <SubHeading title="¿Listo para tu proximo evento?"></SubHeading>
        <Paragraph text="Ya sea que estén planeando una fiesta de cumpleaños, una boda o cualquier otro evento especial, en Con Amore Events estamos listos para ayudarte a hacer de esta ocasión algo verdaderamente inolvidable."></Paragraph>
      </div>
    </div>
  );
};

export default FormText;
