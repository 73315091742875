// SI EN USO

import React from "react";

const AsideInfo = () => {
  return (
    <div className="aside_info_wrapper">
      <button className="aside_close">
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="aside_logo">
        <a href="/" className="light_logo">
          <img src="images/logo.png" alt="logo" />
        </a>
        <a href="/" className="dark_logo">
          <img src="images/logo.png" alt="logo" />
        </a>
      </div>
      <div className="aside_info_inner">
        <p>Con Amore Events | Decoración & Catering</p>

        <div className="aside_info_inner_box">
          <h5>Contacto</h5>
          <p>+1 (321) 310-8486</p>
          <p>
            Orlando <br /> Florida
          </p>
          <p>info@conamoreevents.com</p>
        </div>
        <div className="social_sites">
          <ul className="d-flex align-items-center justify-content-center">
            <li>
              <a href="https://www.facebook.com/Conamoreevents.fl" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/conamoreeventsfl" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AsideInfo;
