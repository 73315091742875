// SI EN USO

import React from "react";

const TextAbout = () => {
  return (
    <section className="highlight_banner bg-dark-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11 p-lg-0">
            <p className="about_para text-center">
              En Con Amore Events{" "}
              <span>
                <a href="#">estamos</a>
              </span>{" "}
              listos para{" "}
              <span>
                <a href="#">llevar tu evento al siguiente nivel</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextAbout;
