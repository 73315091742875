// SI EN USO

import Isotope from "isotope-layout";
import React, { useEffect, useState } from "react";
import PageHeder from "../../components/PageHeder";
import Paragraph from "../../components/Paragraph";
import ProjectCard from "../../components/ProjectElements/ProjectCard";
import SectionGridLines from "../../components/SectionGridLines";
import SectionHeader from "../../components/SectionHeader";

const ProjectGallery = () => {
  const ProjectData01 = [
    {
      id: "1",
      img: "images/portfolio/packery/1.webp",
      details: "Decoración para Eventos",
      tag: "decoracion",
    },
    {
      id: "2",
      img: "images/portfolio/packery/2.webp",
      details: "Decoración para Eventos",
      tag: "decoracion",
    },
    {
      id: "3",
      img: "images/portfolio/packery/3.webp",
      details: "Catering y mesas de quesos",
      tag: "catering",
    },
    {
      id: "4",
      img: "images/portfolio/packery/4.webp",
      details: "Catering y Mesas de Quesos",
      tag: "catering",
    },
    {
      id: "5",
      img: "images/portfolio/packery/5.webp",
      details: "Decoración creativa para tus eventos",
      tag: "eventos",
    },
    {
      id: "6",
      img: "images/portfolio/packery/6.webp",
      details: "Decoración creativa para tus eventos",
      tag: "eventos",
    },
    {
      id: "7",
      img: "images/portfolio/packery/7.webp",
      details: "Decoración de eventos con globos",
      tag: "globos",
    },
    {
      id: "8",
      img: "images/portfolio/packery/8.webp",
      details: "Decoración de eventos con globos",
      tag: "globos",
    },
  ];

  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    Isotope.current = new Isotope(".grid", {
      itemSelector: ".grid-item",
      percentPosition: true,
      // layoutMode: 'packery',
      // masonry: {
      //     columnWidth: '.grid-sizer'
      // },
      transformsEnabled: true,
      transitionDuration: "700ms",
      resize: true,
      fitWidth: true,
      columnWidth: ".grid-sizer",
      options: {
        layoutMode: "masonry",
        masonry: { columnWidth: 100 },
        transitionDuration: "0.5s",
      },
    });

    // cleanup
    return () => Isotope.current.destroy();
  }, []);

  // handling filter key change
  useEffect(() => {
    filterKey === "*" ? Isotope.current.arrange({ filter: `*` }) : Isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <section className="projects packery">
      <SectionGridLines></SectionGridLines>
      <div className="large_font">
        <SectionHeader title="Galeria"></SectionHeader>
      </div>
      <div className="container">
        <div className="section-header text-center has_line">
          <PageHeder className="text-white" title="Conoce más"></PageHeder>
          <div className="section-desc row align-items-center justify-content-center">
            <div className="col-lg-6 text-end">
              <Paragraph text="En Con Amore Events, decoramos tus espacios con amor y creatividad para dejar a tus invitados encantados."></Paragraph>
            </div>
            <div className="col-lg-6 text-start">
              <Paragraph text="Tenemos muchas opciones para todos los gustos y presupuestos. Estamos aquí para ayudarte a crear una historia única con nuestras decoraciones"></Paragraph>
            </div>
          </div>
        </div>

        <div className="portfolio-filters-content">
          <div className="filters-button-group">
            <button className="button" onClick={handleFilterKeyChange("*")}>
              Todo <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("decoracion")}>
              Decoración <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("catering")}>
              Catering <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("eventos")}>
              Eventos <sup className="filter-count"></sup>
            </button>
            <button className="button" onClick={handleFilterKeyChange("globos")}>
              Globos <sup className="filter-count"></sup>
            </button>
          </div>
        </div>
        <div className="grid gutter-20 clearfix">
          <div className="grid-sizer"></div>

          {ProjectData01.map((data) => (
            <ProjectCard key={data.id} data={data} />
          ))}
        </div>
        {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
      </div>
    </section>
  );
};

export default ProjectGallery;
