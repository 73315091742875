// SI EN USO

import $ from "jquery";
import React, { useEffect } from "react";
import SectionGridLines from "../../../components/SectionGridLines";

const Footer = () => {
  //

  useEffect(() => {
    // Totop Button
    $(".totop a").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    // Hide header on scroll down
    const nav = document.querySelector(".header");
    const scrollUp = "top-up";
    let lastScroll = 800;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 800) {
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
        return;
      }

      if (currentScroll > lastScroll) {
        // down
        nav.classList.add(scrollUp);
        $(".totop").addClass("show");
      } else if (currentScroll < lastScroll) {
        // up
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
      }
      lastScroll = currentScroll;
    });
  }, []);
  return (
    <>
      <footer className="footer bg-dark-200 box_padding">
        <div className="footer_inner bg-black">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-4 col-md-2 col-sm-2">
                <div className="section-header">
                  <h2>INFO</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="communication">
                  <div className="info_body">
                    <h6>Email</h6>
                    <h5>info@conamoreevents.com</h5>
                  </div>
                  <div className="info_body">
                    <h6>Teléfono</h6>
                    <h5>+1 (321) 310-8486</h5>
                  </div>
                  <div className="info_body">
                    <h6>Ubicación</h6>
                    <h5>Orlando, Florida</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="footer_elements d-flex align-items-center justify-content-end">
                  <div className="footer_elements_inner">
                    <div className="footer_logo">
                      <a href="/" className="light_logo">
                        <img src="images/logosmall.png" alt="logo" />
                      </a>
                    </div>
                    <div className="footer_social">
                      <ul className="social_list">
                        <li>
                          <a href="https://www.facebook.com/Conamoreevents.fl" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/conamoreeventsfl" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="copyright">
                      <p>Con Amore Events</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionGridLines />
        </div>
      </footer>

      <div className="totop">
        <a href="#">↑</a>
      </div>
    </>
  );
};

export default Footer;
