// SI EN USO

import React, { useState, useEffect, useRef } from "react";

const Video = () => {
  const [videoSrc, setVideoSrc] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    // Assuming the MP4 file is named 'video_con_amore.mp4' in the 'public/videos' folder
    setVideoSrc("images/videos/video_con_amore.mp4");
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="container">
      <div className="video_post">
        <div className="ytube_video">
          {isPlaying ? (
            <video id="ytvideo" controls src={videoSrc} ref={videoRef} autoPlay />
          ) : (
            <>
              <div className="ytplay_btn" onClick={handlePlay}>
                <i className="bi bi-play-fill"></i>
              </div>
              <img src="images/bg/video_bg.webp" alt="video background" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
