// SI EN USO

import React from "react";
import IconBoxFlex from "../../components/IconBoxFlex";
import Paragraph from "../../components/Paragraph";
import SubHeading from "../../components/SubHeading";

const AboutContainer = () => {
  return (
    <section className="about bg-dark-100">
      <div className="large_font">
        <h2 className="floating_element text-dark-200 d-flex justify-content-center">Nosotros</h2>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5">
            <div className="about_image">
              <img src="images/about/a1.webp" alt="img" />
              <img src="images/about/e1.svg" alt="About" />
            </div>
          </div>
          <div className="col-lg-6 col-md-7">
            <div className="about_text_inner">
              <div className="about_text">
                <SubHeading title="Acerca de Nosotros"></SubHeading>
                <Paragraph text="Deseas sorprender a tus invitados con algo especial y elegante. Nosotros nos encargamos de hacer de tu evento algo inolvidable, nos esforzamos por ofrecer un excelente servicio, para que puedas disfrutar de un sabor único y especial."></Paragraph>
              </div>

              <div className="about_icon_box">
                <div className="row">
                  <div className="col-lg-6">
                    <IconBoxFlex img="images/favicon.ico" text="Decoración creativa"></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex img="images/favicon.ico" text="Catering para eventos"></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex img="images/favicon.ico" text="Mesa de quesos"></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex img="images/favicon.ico" text="Papelería creativa"></IconBoxFlex>
                  </div>
                </div>
              </div>

              <div className="btn_group">
                <a href="/contact" className="btn gray">
                  Contactanos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContainer;
