// SI EN USO

import React from "react";

const SliderCard08 = ({ data }) => {
  const { bgImg, details } = data;

  return (
    <div className="slider" style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="container">
        <div className="slide_content">
          <div className="slide_content_wrapper mb-0 h-auto">
            <div className="slide_content_inner">
              <h4>
                <a href="/project-details" className="text-white text-uppercase">
                  {details}
                </a>
              </h4>
              <div className="details_link">
                <a href="/contact">
                  <span className="link_text">Con Amore Events</span>
                  <span className="link_icon">Contacto</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard08;
