import React from "react";
import AboutContainer from "../../containers/AboutContainers/AboutContainer";
import FormContainer from "../../containers/FormContainer";
import ProjectGallery from "../../containers/ProjectContainers/ProjectGallery";
import Services from "../../containers/Services";
import Sliders08 from "../../containers/SliderContainers/Sliders08";

const Home08 = () => {
  return (
    <div className="wrapper">
      <Sliders08 />
      <Services />
      <AboutContainer />
      <ProjectGallery />
      <FormContainer />
    </div>
  );
};

export default Home08;
