// SI EN USO

import React from "react";
import ServiceCard from "../components/ServiceCard";

const ServiceContainer01 = () => {
  return (
    <main className="wrapper">
      <section className="services inner in-service pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ServiceCard
                number="01"
                imgDark="images/logosmall.png"
                imgLight="images/logosmall.png"
                title="Mesas de Quesos"
                text="Las mejores mesas de quesos y aperitivos con productos frescos y la mejor calidad cuidando cada detalle para sorprender y complacer a tus invitados. Para todo tipo de evento."
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="02"
                imgDark="images/logosmall.png"
                imgLight="images/logosmall.png"
                title="Decoración y Catering"
                text="Trabajamos con amor para crear un ambiente único y especial para ti. Si estás pensando en organizar una fiesta inolvidable, no dudes en contactarnos. Trabajaremos contigo para crear una fiesta que se ajuste a tu estilo y presupuesto."
              />
            </div>

            <div className="col-lg-4">
              <ServiceCard
                number="03"
                imgDark="images/logosmall.png"
                imgLight="images/logosmall.png"
                title="Papelería creativa"
                text="Transformamos cualquier espacio en un ambiente mágico y lleno de vida. Nuestras decoraciones son perfectas para cualquier ocasión especial o una fecha que quieras recordar. ¡No lo pienses más y contacta con nosotros!"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceContainer01;
